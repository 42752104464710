import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import logos from "../images/logos.png";

const Navbar = (props) => {
  const navigate = useNavigate();
  const { user, logOut, loading } = useUserAuth();

  const gotologin = () => {
    navigate("/login");
  };

  const signout = () => {
    console.log("clicked");
    logOut();
  };

  // const [stickyClass, setStickyClass] = useState("relative");

  // useEffect(() => {
  //   window.addEventListener("scroll", stickNavbar);

  //   return () => {
  //     window.removeEventListener("scroll", stickNavbar);
  //   };
  // }, []);

  // const stickNavbar = () => {
  //   if (window !== undefined) {
  //     let windowHeight = window.scrollY;
  //     windowHeight > 50 ? setStickyClass("mmm") : setStickyClass("relative");
  //   }
  // };
  return (
    <nav
      // className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark ${stickyClass}`}
      className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark`}
    >
      <div className="container px-0">
        <Link className="navbar-brand logo text-uppercase" to="/">
          <img src={logos} alt="Logo" style={{ height: "70px" }} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav navbar-center ml-auto" id="mySidenav">
            {props.activePage==="Home" ? (
              <li className="nav-item active">
              <Link to={'/'} className="nav-link">
                Home
              </Link>
            </li>
            ):(
              <li className="nav-item">
                 <Link to={'/'} className="nav-link">
                  Home
                </Link>
              </li>
            )}

            {props.activePage==="About" ? (
              <li className="nav-item active">
                <Link to={'/about'} className="nav-link">
                  About
                </Link>
              </li>
            ):(
              <li className="nav-item">
                <Link to={'/about'} className="nav-link">
                  About
                </Link>
              </li>
            )}
           
            {props.activePage==="Services" ? (
               <li className="nav-item active">
                <Link to={'/services'} className="nav-link">
                  Services
                </Link>
              </li>
            ):(
              <li className="nav-item">
                <Link to={'/services'} className="nav-link">
                  Services
                </Link>
              </li>
            )}

            {/* <li className="nav-item">
              <Link to="/team" className="nav-link">
                Team
              </Link>
            </li> */}
            
            {props.activePage==="Events" ? (
            <li className="nav-item active">
              <Link to={'/events'} className="nav-link">
                Events
              </Link>
            </li>
            ):(
            <li className="nav-item">
              <Link to={'/events'} className="nav-link">
                Events
              </Link>
            </li>
            )}
            <li className="nav-item" id="translator">
              <div id="google_translate_element"></div>
            </li>
            {user.uid ? (
              <li className="nav-item">
                <Link to={'/myorders'} className="nav-link">
                  My Orders
                </Link>
              </li>
            ) : (
              <></>
            )}
          </ul>
          <div className="nav-button">
            <ul className="nav navbar-nav navbar-right">
              <li>
                {loading ? (
                  <button
                    type="button"
                    className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light loginBtn"
                  >
                    Loading
                  </button>
                ) : user.uid ? (
                  <button
                    type="button"
                    className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light loginBtn"
                    onClick={() => signout()}
                  >
                    Logout
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light loginBtn"
                    onClick={gotologin}
                  >
                    Login
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
