import Home from "./pages/Home";
import ServiceDetails from "./pages/ServiceDetails";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUs from "./pages/ContactUs";
import About from "./pages/About";
import Events from "./pages/Events";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import MyOrders from "./pages/MyOrders";
import ServicesPage from "./pages/Services";
import TeamPage from "./pages/Team";
import CreateOrder from "./pages/CreateOrder";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <UserAuthContextProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/servicedetails" element={<ServiceDetails />} />
            <Route path="/createorder" element={<CreateOrder />} />
            <Route path="/myorders" element={<MyOrders />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/events" element={<Events />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </UserAuthContextProvider>
  );
}

export default App;
