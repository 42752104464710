import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import FooterLinks from "../components/FooterLinks";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { onValue, ref, db, set, storage, push } from "../config/firebase";
import { Table, Modal } from "react-bootstrap";
import Navbar from "../components/Navbar";
import StarRatingComponent from "react-star-rating-component";
import {
  ref as firebaseStorageRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

const MyOrders = () => {
  const { user, loading } = useUserAuth();
  const [orders, setOrders] = useState([]);
  const [currentorderid, setCurrentOrderId] = useState("");
  const [amount, setAmount] = useState(0);
  const [checkoutShow, setCheckoutShow] = useState(false);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [cardname, setCardName] = useState("");
  const [cardno, setCardno] = useState("");
  const [expmonth, setExpmonth] = useState("");
  const [expyear, setExpyear] = useState("");
  const [cvv, setCvv] = useState("");
  const [descriptionShow, setDescriptionShow] = useState(false);
  const [description, setDescription] = useState("");

  const [currentOrder, setCurrentOrder] = useState("");
  const [currentService, setCurrentService] = useState("");
  const [reviewShow, setReviewShow] = useState(false);
  const [company, setCompany] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(1);
  const [country, setCountry] = useState("");
  const [image, setImage] = useState("");
  const [progresspercent, setProgresspercent] = useState(0);

  useEffect(() => {
    const userRef = ref(db, "orders/");

    onValue(userRef, async (snapshot) => {
      if (snapshot.val()) {
        const data = Object.values(snapshot.val());
        let list = [];
        await data.forEach((order) => {
          console.log(order);
          if (order.userid === user.uid) {
            list.push(order);
          }
        });

        console.log(list);
        setOrders(list);
      }
    });
  }, [user]);

  const opencheckoutmodal = (amount, key) => {
    setCurrentOrderId(key);
    setAmount(amount);
    setCheckoutShow(true);
  };

  const opendescriptionmodal = (description) => {
    setDescription(description);
    setDescriptionShow(true);
  };

  const makesession = async (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const checkout = async () => {
    setDisabled(true);

    if (
      cardname === "" ||
      cardno === "" ||
      expmonth === "" ||
      expyear === "" ||
      cvv === ""
    ) {
      alert("Please Provide All Details");
      setDisabled(false);
    } else {
      let sessionid = await makesession(25);
      console.log(sessionid);

      const orderRef = ref(db, "sessions/" + sessionid);
      await set(orderRef, {
        userid: user.uid,
        session: sessionid,
        amount: amount,
      });

      setTimeout(async () => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: user.uid,
            cardno: cardno,
            expmonth: expmonth,
            expyear: expyear,
            cvv: cvv,
            cardname: cardname,
            email: user.email,
            session: sessionid,
            orderid: currentorderid,
          }),
        };
        const response = await fetch(
          "https://us-central1-webtech-services.cloudfunctions.net/webApi/api/v1/newcheckout",
          requestOptions
        );
        const data = await response.json();
        console.log(data);
        if (data.status === "succeeded") {
          alert("Payment Completed");
          setCheckoutShow(false);

          //save data to firebase here or we can also save data in firebase from server

          setDisabled(false);
        } else {
          alert(data.error);
          setDisabled(false);
        }
      }, 500);
    }
  };
  const completeOrder = (orderss) => {
    let obj = {
      key: orderss.key,
      date: orderss.date,
      firstname: orderss.firstname,
      lastname: orderss.lastname,
      email: orderss.email,
      serviceid: orderss.serviceid,
      servicename: orderss.servicename,
      description: orderss.description,
      userid: orderss.userid,
      status: "Completed",
      amount: orderss.amount,
      paymentstatus: orderss.paymentstatus,
      workstatus: "Work Send",
      work: orderss.work,
      review: false,
    };

    console.log(obj);

    set(ref(db, "orders/" + obj.key), obj)
      .then(() => {
        // Data saved successfully!
        alert("Order Completed Successfully!");
      })
      .catch((error) => {
        // The write failed...
        alert("Failed!");
      });
  };

  const openreviewmodal = (order, serviceid) => {
    setCurrentOrder(order);
    setCurrentService(serviceid);
    setReviewShow(true);
  };

  const handleSubmit = (e) => {
    console.log(e);
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = firebaseStorageRef(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL);
        });
      }
    );
  };

  const addReview = () => {
    const obj = {
      image,
      review,
      rating,
      company,
      country,
    };
    console.log(obj);
    if (
      image === "" ||
      review === "" ||
      rating === "" ||
      company === "" ||
      country === ""
    ) {
      alert("Please Fill All Details");
    } else {
      const reviewRef = ref(db, "reviews/" + currentService);

      let o = {
        userid: user.uid,
        company: company,
        rating: rating,
        review: review,
        name: user.firstname + " " + user.lastname,
        currentService: currentService,
        image: image,
        country: country,
        key: push(reviewRef).key,
      };
      set(ref(db, "reviews/" + currentService + "/" + o.key), o).then(() => {
        let obj = {
          key: currentOrder.key,
          date: currentOrder.date,
          firstname: currentOrder.firstname,
          lastname: currentOrder.lastname,
          email: currentOrder.email,
          serviceid: currentOrder.serviceid,
          servicename: currentOrder.servicename,
          description: currentOrder.description,
          userid: currentOrder.userid,
          status: currentOrder.status,
          amount: currentOrder.amount,
          paymentstatus: currentOrder.paymentstatus,
          workstatus: currentOrder.workstatus,
          work: currentOrder.work,
          review: true,
        };

        console.log(obj);

        set(ref(db, "orders/" + obj.key), obj)
          .then(() => {
            // Data saved successfully!
            alert("Review Added Successfully!");
            setReviewShow(false);
          })
          .catch((error) => {
            // The write failed...
            alert("Failed!");
          });
      });
    }
  };

  if (loading) {
    return (
      <div style={{ backgroundColor: "#333", height: "100vh" }}>
        <h1 className="text-center text-white" style={{ paddingTop: "30%" }}>
          Loading...
        </h1>
      </div>
    );
  } else if (loading === false && user.uid) {
    return (
      <>
        {/* Navbar Component*/}
        <Navbar />
        <section
          className="section bg-home home-half"
          id="home"
          data-image-src="images/bg-home.jpg"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 className="home-title">My Orders</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-pattern-effect">
            <img src="images/bg-pattern.png" alt="" />
          </div>
        </section>

        <br />
        <br />
        <br />
        <section class="backWhite">
          <div className="container">
            <Table responsive striped bordered>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Order Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Payment Status</th>
                  <th>Your Work</th>
                  <th>Order Completion</th>
                  <th>Review</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{order.date}</td>
                      <td>{order.status}</td>
                      <td>{order.amount}</td>
                      <td>
                        <button
                          className="btn btn-custom btn-sm"
                          onClick={() =>
                            opendescriptionmodal(order.description)
                          }
                        >
                          View Description
                        </button>
                      </td>

                      <td>
                        {order.status === "In-Voice Sent" ? (
                          <button
                            className="btn btn-custom btn-sm"
                            onClick={() =>
                              opencheckoutmodal(order.amount, order.key)
                            }
                          >
                            Pay Now
                          </button>
                        ) : (
                          order.paymentstatus
                        )}
                      </td>

                      <td>
                        {order.workstatus === "Work Send" ? (
                          <a
                            href={order.work}
                            target="_blank"
                            className="btn btn-custom btn-sm text-white"
                          >
                            Download Work
                          </a>
                        ) : (
                          order.workstatus
                        )}
                      </td>
                      <td>
                        {order.status === "Work Send" ? (
                          <button
                            className="btn btn-custom btn-sm"
                            onClick={() => completeOrder(order)}
                          >
                            Complete Order
                          </button>
                        ) : (
                          order.status
                        )}
                      </td>
                      <td>
                        {order.review === false &&
                        order.status === "Completed" ? (
                          <button
                            className="btn btn-custom btn-sm"
                            onClick={() =>
                              openreviewmodal(order, order.serviceid)
                            }
                          >
                            Review
                          </button>
                        ) : (
                          order.status
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </section>

        {/* Footer Component*/}
        <Footer />

        {/* FooterLinks Component*/}
        <FooterLinks />
        <Modal
          size="lg"
          show={reviewShow}
          onHide={() => setReviewShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <center>
                    {image ? (
                      <>
                        <img
                          src={image}
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "25px",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </center>
                  <label>Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleSubmit(e)}
                  />
                  {progresspercent > 0 ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: progresspercent + "%" }}
                        aria-valuenow={progresspercent}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {progresspercent}%
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Company Name Or Individual Work Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Rating</label>
                  <div style={{ marginTop: "20px" }}>
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={rating}
                      onStarClick={(nextValue) => setRating(nextValue)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Review</label>
                  <textarea
                    className="form-control"
                    rows="5"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button
                    className="btn btn-custom btn-block"
                    onClick={addReview}
                  >
                    Add Review
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={descriptionShow}
          onHide={() => setDescriptionShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Description
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={checkoutShow}
          onHide={() => setCheckoutShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Pay Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="cc-name">Name on card</label>
                <input
                  type="text"
                  class="form-control"
                  id="cc-name"
                  placeholder="Enter Your Name On Card"
                  required=""
                  style={{ margin: 0 }}
                  value={cardname}
                  onChange={(e) => setCardName(e.target.value)}
                />
                <small class="text-muted">Full name as displayed on card</small>
                <div class="invalid-feedback">Name on card is required</div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="cc-number">Credit card number</label>
                <input
                  type="text"
                  class="form-control"
                  id="cc-number"
                  placeholder="Enter Your Card Number"
                  required=""
                  style={{ margin: 0 }}
                  value={cardno}
                  onChange={(e) => setCardno(e.target.value)}
                />
                <small class="text-muted">xxxx-xxxx-xxxx-xxxx</small>

                <div class="invalid-feedback">
                  Credit card number is required
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="cc-expiration">Expiry Month</label>
                <select
                  id="expmonth"
                  name="expmonth"
                  placeholder="September"
                  value={expmonth}
                  style={{ margin: 0 }}
                  className="form-control"
                  onChange={(e) => setExpmonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  <option value="01">Janaury</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <small class="text-muted">Expiry Month</small>

                <div class="invalid-feedback">Expiration date required</div>
              </div>
              <div className="col-md-4">
                <label htmlFor="expyear">Exp Year</label>
                <input
                  type="text"
                  id="expyear"
                  name="expyear"
                  placeholder={2018}
                  className="form-control"
                  style={{ margin: 0 }}
                  value={expyear}
                  onChange={(e) => setExpyear(e.target.value)}
                />
                <small class="text-muted">Expiry Year</small>
              </div>
              <div class="col-md-4 mb-3">
                <label for="cc-expiration">CVV</label>
                <input
                  type="text"
                  class="form-control"
                  id="cc-cvv"
                  placeholder="Enter Your Card CVV"
                  required=""
                  style={{ margin: 0 }}
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                />
                <small class="text-muted">Card CVV</small>

                <div class="invalid-feedback">Security code required</div>
              </div>
            </div>
            <br />
            <button
              className="btn btn-custom"
              onClick={checkout}
              disabled={disabled}
            >
              Submit
            </button>
          </Modal.Body>
        </Modal>
      </>
    );
  } else if (
    loading === false &&
    (user.uid === null || user.uid === undefined)
  ) {
    return <Navigate to="/login" />;
  }
};
export default MyOrders;
