import React from "react";
import Navbar from "../components/Navbar";
import FooterLinks from "../components/FooterLinks";
import AboutUs from "../components/AboutUs";
import EventBox from "../components/EventBox";
import Contact from "../components/Contact";
import Chat from "../components/Chat";

const Events = () => {
  return (
    <>
    {/* Navbar Component*/}
    <Navbar activePage = "Events" />

    <section id="mainHome" className="mainHomeAbout">
      <div className="container">
        <div className="row mainRow mainRowAbout">
          <div className="col-lg-6 centerCols">
            <div className="w-100">
            <h1 className="mainTitle text-center">Coming Soon</h1>
            {/* <p className="mainPara">
            Lorem ipsum dolor sit amet consectetur. Quis ultrices pulvinar dui at. Erat est eu iaculis consectetur. Ullamcorper sit congue nunc urna. At eros iaculis vitae vitae sapien tellus ut viverra. Ultrices aliquam quis sed in neque. Velit ac scelerisque massa.
            </p> */}
            </div>
          </div>
          <div className="col-lg-6 centerCols centerColsAbout">
            <img className="mainGifAbout" src="images/Gifs/eventsGif.gif"></img>
          </div>
        </div>
      </div>
      <div id="circle1"></div>
      <div id="circle2"></div>
    </section>


    {/* EventBox Component*/}
    {/* <EventBox /> */}

    {/* Contact Component*/}
    {/* <Contact /> */}

    {/* FooterLinks Component*/}
    <FooterLinks />
      {/* Chat Component*/}
      <Chat />
    </>
  );
};

export default Events;
